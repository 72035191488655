<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号:">
              <a-input
                v-model:value.trim="where.batteryCode"
                placeholder="请输入电池编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池品牌:">
              <a-input
                v-model:value.trim="where.brand"
                placeholder="请输入电池品牌"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="设备编号:">
              <a-input
                v-model:value.trim="where.deviceCode"
                placeholder="请输入设备编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="batteryId"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
      >
        <template #protocolType="{ record }">
          <span>
            {{ typeProtocol(record.protocolType) }}
          </span>
        </template>
        <template #action="{ }">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              更多操作
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="moreOnClick">
                <a-menu-item key="监控">监控</a-menu-item>
                <a-menu-item key="轨迹">轨迹</a-menu-item>
                <a-menu-item key="备注">备注</a-menu-item>
                <a-menu-item key="操作日志">操作日志</a-menu-item>
                <a-menu-item key="查看二维码">查看二维码</a-menu-item>
                <a-menu-item key="远程监控">远程监控</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </ud-pro-table>
    </a-card>
  </div>
</template>
<script>
import * as batteryApi from '@/api/rnt/battery'
import { defineComponent } from 'vue'
import { DownOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'batteryList',
  components: {
    DownOutlined
  },
  data() {
    return {
      name: 'batterytList',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '电池编号',
          dataIndex: 'batteryCode',
          sorter: true
        },
        {
          title: '电池品牌',
          dataIndex: 'brand',
          sorter: true
        },
        {
          title: '设备编号',
          dataIndex: 'deviceCode',
          sorter: true
        },
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '电池协议',
          dataIndex: 'protocolType',
          sorter: true,
          slots: { customRender: 'protocolType' }
        },
        {
          title: '运营商名称',
          dataIndex: 'operatorName',
          sorter: true
        },
        {
          title: '定位状态',
          dataIndex: 'pointName',
          sorter: true,
          slots: { customRender: 'pointName' }
        },
        {
          title: '所属租赁点名称',
          dataIndex: 'pointName',
          sorter: true,
          slots: { customRender: 'pointName' }
        },
        {
          title: '仓库名称',
          dataIndex: 'warehouseName',
          sorter: true
        },
        {
          title: '更多',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 是否显示详情弹窗
      showInfo: false,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  created() {},
  methods: {

    moreOnClick(key) {
      console.log(key)
    },
    typeProtocol(protocolType) {
      if (protocolType === 47) {
        return 'first'
      }
      if (protocolType === 48) {
        return 'second'
      }
    },
    datasource(option, callback) {
      batteryApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    }
  }
})
</script>

<style scoped>
</style>
